import styled, { css } from "styled-components";

import { styleBodyM, styleBodyS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import theme from "styled-theming";

const positive = theme("theme", {
  undefined: "--text-green-on-neutral",
  lightgray: "--text-green-on-neutral",
  white: "--text-green-on-neutral",
  blue: "--text-green-on-blue",
  cyan: "--text-green-on-neutral",
});

const negative = theme("theme", {
  undefined: "--text-red-on-neutral",
  lightgray: "--text-red-on-neutral",
  white: "--text-red-on-neutral",
  blue: "--text-red-on-blue",
  cyan: "--text-red-on-neutral",
});

export const ColoredNumber = styled.span<ColoredNumberProps>`
  ${styleBodyS};
  ${(props) =>
    props.$positive
      ? css`
          color: var(${positive});
        `
      : css`
          color: var(${negative});
        `}
  ${(props) =>
    props.$size === "M"
      ? css`
          margin-left: 1rem;
          ${MQ.FROM_L} {
            ${styleBodyM};
            line-height: 1.5rem;
            ${props.$positive
              ? css`
                  color: var(${positive});
                `
              : css`
                  color: var(${negative});
                `}
          }
        `
      : css`
          margin-left: 0.5rem;
        `}
`;

interface ColoredNumberProps {
  readonly $positive?: boolean;
  readonly $size?: string;
}
