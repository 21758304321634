import React, { Children, ReactElement, useContext } from "react";

import {
  CardLink,
  TittleText,
  RightIcon,
  AggregateFiguresContainer,
  Wrapper,
  HeadingContainer,
  AggregateFigures,
  AggregateDevelopment,
  CardWrapper,
  CardAccordion,
  SimpleCard,
  AggregateSkeletonContainer,
} from "./ProductCardList.styled";
import ProductCardListProps from "./ProductCardListProps";
import { ThemeContext, ThemeProvider } from "styled-components";
import ProductCardItem from "components/ProductCardItem";
import AccordionItem from "components/AccordionItem";
import Skeleton from "components/Skeleton";
import { useBreakpoint } from "hooks";

const ProductCardList = ({
  id,
  theme,
  contentTheme,
  title,
  aggregateFigures,
  aggregateDescription,
  children,
  loading,
  contentId,
  className,
  anchorName,
}: ProductCardListProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};
  const breakpoint = useBreakpoint();

  return (
    <ThemeProvider
      theme={{
        theme: theme || exitingTheme["theme"],
        contentTheme: contentTheme || exitingTheme["contentTheme"],
      }}
    >
      <Wrapper className={className} id={id || contentId || anchorName}>
        <HeadingContainer className="header">
          {loading ? (
            <Skeleton
              variant="text"
              width={breakpoint === "S" ? "130px" : "200px"}
            >
              <TittleText>title</TittleText>
            </Skeleton>
          ) : (
            <TittleText
              className="title-text"
              $fullWidth={!(aggregateFigures || aggregateDescription)}
            >
              {title}
            </TittleText>
          )}
          <AggregateFiguresContainer className="table-header">
            {loading ? (
              <AggregateSkeletonContainer>
                <Skeleton variant="text" width={"150px"}>
                  <AggregateFigures>aggregateFigures</AggregateFigures>
                </Skeleton>
              </AggregateSkeletonContainer>
            ) : (
              aggregateFigures && (
                <AggregateFigures>{aggregateFigures}</AggregateFigures>
              )
            )}
            {loading ? (
              <AggregateSkeletonContainer>
                <Skeleton variant="text" width={"100px"}>
                  <AggregateDevelopment>
                    aggregateDescription
                  </AggregateDevelopment>
                </Skeleton>
              </AggregateSkeletonContainer>
            ) : (
              aggregateDescription && (
                <AggregateDevelopment>
                  {aggregateDescription}
                </AggregateDevelopment>
              )
            )}
          </AggregateFiguresContainer>
        </HeadingContainer>
        {loading ? (
          <CardWrapper>
            <CardLink $loading>
              <ProductCardItem loading />
              <RightIcon aria-hidden="true" />
            </CardLink>
          </CardWrapper>
        ) : (
          children &&
          Children.map(children, (child, index) => {
            if (child.props.url)
              return (
                <CardWrapper key={index}>
                  <CardLink {...child.props} underline="none">
                    <ProductCardItem {...child.props} />
                    <RightIcon aria-hidden="true" />
                  </CardLink>
                </CardWrapper>
              );
            else if (child.props.children)
              return (
                <CardWrapper key={`CardWrapper${index}`}>
                  <CardAccordion>
                    <AccordionItem
                      key={`Accordion${index}`}
                      id={`Accordion${index}`}
                      theme={theme}
                    >
                      <ProductCardItem
                        key={`ProductCard${index}`}
                        {...child.props}
                      />
                      {child.props.children}
                    </AccordionItem>
                  </CardAccordion>
                </CardWrapper>
              );
            else {
              return (
                <CardWrapper key={index}>
                  <SimpleCard>
                    <ProductCardItem {...child.props} />
                  </SimpleCard>
                </CardWrapper>
              );
            }
          })
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default React.memo(ProductCardList);
