import classNames from "classnames";
import React, { ReactElement, useCallback } from "react";
import { ThemeProvider } from "styled-components";

import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import Icon from "components/Icon";

import {
  HelperText,
  IconArrow35,
  IconArrow28,
  StyledButton,
  Wrapper,
  StyledLink,
} from "./Button.styled";
import ButtonProps, { SubmitButtonProps } from "./ButtonProps";

const Button = ({
  actionTheme,
  text,
  linkText,
  icon,
  url,
  size = "medium",
  variant = "outline",
  disabled = false,
  iconOnly = false,
  isLink,
  target,
  primaryButton,
  children,
  className,
  style,
  type = "button",
  name,
  editPropertyName,
  component,
  onClick,
  onKeyDown,
  contentId,
  ...props
}: ButtonProps): ReactElement => {
  const isDisabled = disabled || false;

  if (primaryButton) {
    variant = "primary";
    actionTheme = actionTheme === "black" ? "blue" : actionTheme;
  }

  text = text || linkText;
  if (children && typeof children === "string" && !text) text = children;
  const arrow =
    isLink || className === "embedded" ? (
      size === "large" ? (
        <IconArrow35 aria-hidden="true" />
      ) : (
        <IconArrow28 aria-hidden="true" />
      )
    ) : (
      <></>
    );
  const InnerContent = (
    <>
      {icon &&
        !isLink &&
        (typeof icon === "string" ? (
          <Icon icon={icon} aria-hidden="true" />
        ) : (
          (icon as React.ReactElement)
        ))}
      {text ? (
        isLink ? (
          appendComponentWithNoBr(text, arrow)
        ) : (
          <span>{text}</span>
        )
      ) : (
        <span>{children}</span>
      )}
    </>
  );
  const classes = classNames(className, { disabled: disabled });

  const buttonTheme: ButtonProps = {
    size: size,
  };
  if (actionTheme) buttonTheme.actionTheme = actionTheme;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) onClick(event);
    },
    [onClick]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (onKeyDown) onKeyDown(event);
    },
    [onKeyDown]
  );

  return (
    <ThemeProvider theme={buttonTheme}>
      {isDisabled || !url ? (
        <StyledButton
          className={classes}
          $internalVariant={variant}
          aria-disabled={isDisabled}
          tabIndex={isDisabled ? -1 : 0}
          disabled={isDisabled}
          $iconOnly={iconOnly}
          type={type}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          style={style}
          name={name}
          id={contentId}
          {...props}
        >
          {InnerContent}
        </StyledButton>
      ) : (
        <StyledLink
          className={classes}
          to={url}
          $internalVariant={variant}
          target={target}
          $iconOnly={iconOnly}
          style={style}
          component={component}
          id={contentId}
        >
          {InnerContent}
        </StyledLink>
      )}
    </ThemeProvider>
  );
};

export const SubmitButton = ({
  elementName,
  label,
  description,
  actionTheme,
  icon,
  isLink,
  size = "large",
  displayOption = "Full",
  disabled = false,
  style,
  className,
  editPropertyName,
  onClick,
  onKeyDown,
  type = "submit",
}: SubmitButtonProps): ReactElement => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) onClick(event);
    },
    [onClick]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (onKeyDown) onKeyDown(event);
    },
    [onKeyDown]
  );

  return (
    <ThemeProvider
      theme={{
        displayOption: displayOption,
      }}
    >
      <Wrapper>
        <Button
          className={className}
          name={elementName}
          disabled={disabled}
          actionTheme={actionTheme}
          variant="primary"
          size={size}
          type={type}
          style={style}
          isLink={isLink}
          icon={icon}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          editPropertyName={editPropertyName}
        >
          {label}
        </Button>
        {description && <HelperText>{description}</HelperText>}
      </Wrapper>
    </ThemeProvider>
  );
};

export default React.memo(Button);
