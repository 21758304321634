import styled, { css } from "styled-components";

import {
  styleBodyM,
  styleBodyS,
  styleHeadingM,
  styleHeadingS,
} from "style/components/Typography";
import theme from "styled-theming";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
  default: "--color-black",
  white: "--color-black",
  lightgray: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;

  ${(props) =>
    props.$bottomBorder
      ? css`
          border-bottom: 1px solid var(${textColor});
        `
      : css``}
`;

export const Title = styled.div`
  ${styleHeadingS};
  grid-column: col-start 1 / span 4;
  grid-row: 3;
  ${MQ.FROM_XL} {
    ${styleHeadingM};
  }
`;

export const Text = styled.div<TextProps>`
  ${styleBodyS};
  grid-column: col-start 1 / span 4;
  grid-row: 3;

  ${(props) =>
    props.size === "M"
      ? css`
          ${styleBodyM}
        `
      : css``}
`;

interface TextProps {
  readonly size?: string;
}

interface ContainerProps {
  readonly $bottomBorder: boolean;
}
