import { FormControl, FormHelperText } from "@mui/material";
import styled, { css } from "styled-components";
import theme from "styled-theming";

import { LongArrowRight28, LongArrowRight35 } from "icons";
import { styleLabelM, styleLabelS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import Link from "components/Link";

const backgroundColor = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-blue",
    coral: "--color-coral",
    cyan: "--color-cyan-l60",
  },
  outline: {
    blue: "--color-transparent",
    black: "--color-transparent",
    cyan: "--color-transparent",
    white: "--color-transparent",
    coral: "--color-transparent",
  },
});

const backgroundColorHover = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-blue-l05",
    coral: "--color-coral-l10",
    cyan: "--color-cyan-l70",
  },
  outline: {
    blue: "--color-blue",
    black: "--color-blue",
    cyan: "--color-cyan-l60",
    white: "--color-blue",
    coral: "--color-coral",
  },
});

const color = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-cyan-l60",
    coral: "--color-blue",
    cyan: "--color-blue",
  },
  outline: {
    blue: "--color-blue",
    black: "--color-black",
    cyan: "--color-cyan-l60",
    white: "--color-white",
    coral: "--color-coral",
  },
});

const colorHover = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-cyan-l60",
    coral: "--color-blue",
    cyan: "--color-blue",
  },
  outline: {
    blue: "--color-cyan-l60",
    black: "--color-cyan-l60",
    cyan: "--color-blue",
    white: "--color-cyan-l60",
    coral: "--color-blue",
  },
});

const colorHoverIconOnly = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-blue-d10",
    coral: "--color-coral-l10",
    cyan: "--color-cyan-l80",
  },
  outline: {
    blue: "--color-blue-d10",
    black: "--color-cyan-d05",
    cyan: "--color-cyan-l80",
    white: "--color-cyan-l60",
    coral: "--color-coral-l10",
  },
});

const borderColor = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-blue",
    coral: "--color-coral",
    cyan: "--color-cyan-l60",
  },
  outline: {
    blue: "--color-blue-o15",
    black: "--color-black-o15",
    cyan: "--color-cyan-o40",
    white: "--color-white-o30",
    coral: "--color-coral-o40",
  },
});

const borderColorHover = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-blue-l05",
    coral: "--color-coral-l10",
    cyan: "--color-cyan-l70",
  },
  outline: {
    blue: "--color-blue",
    black: "--color-blue",
    cyan: "--color-cyan-l60",
    white: "--color-blue",
    coral: "--color-coral",
  },
});

const disabledBackgroundColor = theme.variants(
  "actionTheme",
  "$internalVariant",
  {
    primary: {
      blue: "--color-black-o15",
      coral: "--color-black-o15",
      cyan: "--color-black-o15",
    },
    outline: {
      blue: "--color-transparent",
      black: "--color-transparent",
      cyan: "--color-transparent",
      white: "--color-transparent",
      coral: "--color-transparent",
    },
  }
);

const disabledColor = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-white",
    coral: "--color-white",
    cyan: "--color-white",
  },
  outline: {
    blue: "--color-blue-o15",
    black: "--color-black-o30",
    cyan: "--color-cyan-o40",
    white: "--color-white-o30",
    coral: "--color-cyan-o40",
  },
});

const disabledBorderColor = theme.variants("actionTheme", "$internalVariant", {
  primary: {
    blue: "--color-transparent",
    coral: "--color-transparent",
    cyan: "--color-transparent",
  },
  outline: {
    blue: "--color-blue-o15",
    black: "--color-black-o15",
    cyan: "--color-cyan-o40",
    white: "--color-white-o30",
    coral: "--color-cyan-o40",
  },
});

const verticalAlign = theme("size", {
  small: "-0.5625rem",
  medium: "-0.5625rem",
  large: "-0.75rem",
});

const buttonDefaults = css`
  all: unset;
  ${styleLabelM};
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: inline-block;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  &:focus-visible {
    outline: 7px solid var(--color-cyan-o20);
    outline-offset: 0;
  }

  span,
  svg {
    color: inherit;
  }

  &.embedded {
    margin: 1rem 0;
  }
`;

export const hideVisually = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const buttonStyle = css<variantProp>`
  ${buttonDefaults};
  background: var(${backgroundColor});
  border: 1px solid var(${borderColor});
  color: var(${color});
  padding: ${(props) =>
    props.$iconOnly
      ? 0
      : theme("size", {
          small: "0.375rem",
          medium: "0.375rem 0.8125rem",
          large: "0.8125rem 2.5625rem",
        })};

  ${(props) =>
    props.$iconOnly &&
    css`
      border: none;
    `}

  ${MQ.FROM_M} {
    padding: ${(props) =>
      props.$iconOnly
        ? 0
        : theme("size", {
            medium: "0.8125rem 1.6875rem",
            large: "1.25rem 4.3125rem",
          })};
  }

  ${MQ.FROM_XL} {
    padding: ${(props) =>
      props.$iconOnly
        ? 0
        : theme("size", {
            medium: "0.8125rem 1.6875rem",
            large: "1.6875rem 6.0625rem",
          })};
  }

  &:hover {
    border-bottom-color: var(${borderColor});
  }

  @media (hover: hover) {
    &:hover {
      border-color: var(${borderColorHover});
      color: var(
        ${(props) => (props.$iconOnly ? colorHoverIconOnly : colorHover)}
      );

      &:not(.disabled) {
        ${(props) =>
          !props.$iconOnly &&
          css`
            background: var(${backgroundColorHover});
          `}
      }
    }
  }

  span {
    ${(props) => props.$iconOnly && hideVisually};
  }

  &.disabled {
    background: var(${disabledBackgroundColor});
    border-color: var(${disabledBorderColor});
    color: var(${disabledColor});

    span {
      color: var(${disabledColor});
    }

    &:hover {
      border-bottom-color: var(${disabledBorderColor});
    }

    @media (hover: hover) {
      &:hover {
        cursor: not-allowed;
        text-decoration: none;
        border-color: var(${disabledBorderColor});
        color: var(${disabledColor});

        span {
          color: var(${disabledColor});
        }
      }
    }
  }
`;

export const StyledLink = styled(Link)<variantProp>`
  && {
    ${buttonStyle};
  }
`;

export const StyledButton = styled.button`
  ${buttonStyle};
  & > svg {
    margin-right: ${(props) => (props.$iconOnly ? "0" : "0.4375rem")};

    ${MQ.FROM_M} {
      ${(props) => (props.$iconOnly ? "0" : "0.875rem")};
    }
  }
`;

export const ButtonGroup = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const IconArrow35 = styled(LongArrowRight35)`
  margin-left: 0.4375rem;
  vertical-align: ${verticalAlign};

  ${MQ.FROM_M} {
    margin-left: 0.875rem;
  }
`;

export const IconArrow28 = styled(LongArrowRight28)`
  margin-left: 0.4375rem;
  vertical-align: ${verticalAlign};

  ${MQ.FROM_M} {
    margin-left: 0.875rem;
  }
`;

export const Wrapper = styled(FormControl)`
  grid-column: col-start 1 / span 2;

  ${MQ.FROM_M} {
    grid-column: ${theme("displayOption", {
      Full: "auto / span 6",
      Half: "auto / span 3",
      OneThird: "auto / span 2",
      TwoThirds: "auto / span 4",
    })};
  }
`;

export const HelperText = styled(FormHelperText)`
  && {
    ${styleLabelS};
    color: var(--color-black-o50);
    margin-left: 0;
  }
`;

interface variantProp {
  readonly $internalVariant: "primary" | "outline";
  readonly $iconOnly?: boolean;
}
