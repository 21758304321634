import styled, { css } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleBodyM } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
  undefined: "--color-black",
  default: "--color-black",
  white: "--color-black",
  lightgray: "--color-black",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const linkColor = theme("theme", {
  undefined: "--color-cyan-d05",
  default: "--color-cyan-d05",
  white: "--color-cyan-d05",
  lightgray: "--color-cyan-d05",
  blue: "--color-cyan-l60",
  cyan: "--color-blue",
});

const borderColor = theme("theme", {
  undefined: "--color-cyan-d05-o30",
  default: "--color-cyan-d05-o30",
  white: "--color-cyan-d05-o30",
  lightgray: "--color-cyan-d05-o30",
  blue: "--color-cyan-l60-o30",
  cyan: "--color-blue-o30",
});

const hoverColor = theme("theme", {
  undefined: "--color-cyan-d05",
  default: "--color-cyan-d05",
  white: "--color-cyan-d05",
  lightgray: "--color-cyan-d05",
  blue: "--color-coral",
  cyan: "--color-coral",
});

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "--color-black-o05",
  white: "--color-white",
  lightgray: "--color-black-l95",
  blue: "--color-blue-d10",
  cyan: "--color-cyan-l80",
});

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(${backgroundColor});

  ${theme("variant", {
    default: css`
      position: sticky;
      top: 0;
      z-index: 100;
    `,
  })};
`;

export const Container = styled(GridContainer)`
  ${theme("variant", {
    default: css`
      padding: 0.875rem 0rem;
      position: relative;

      ${MQ.FROM_M} {
        padding: 0.875rem ${GUTTER.MD};
      }

      ${MQ.FROM_XL} {
        padding: 1.3125rem ${GUTTER.XL};
      }
    `,
    pills: css`
      padding: 1.5rem 0 0;
    `,
  })};
`;

export const ToggleButtonGroup = styled.ul`
  display: flex;
  margin: 0;
  padding-inline-start: 0;
  row-gap: 0.4375rem;
  width: 100%;
  ${theme("variant", {
    default: css`
      column-gap: 0rem;
      flex-flow: row nowrap;
      overflow-x: auto;

      ${MQ.FROM_M} {
        column-gap: 2.1875rem;
        flex-wrap: wrap;
        overflow: none;
      }
    `,
    pills: css`
      column-gap: 0.875rem;
      flex-flow: row wrap;
    `,
  })};

  ${MQ.FROM_M} {
    row-gap: 0.875rem;
  }
`;

export const ToggleButtonWrapper = styled.div`
  color: var(${textColor});
  list-style: none;
  white-space: nowrap;
`;

export const styledToggleButton = css<StyledToggleButtonProps>`
  color: var(${textColor});
  border-bottom: none;
  cursor: pointer;
  text-decoration: none;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;

  span {
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  ${(props) =>
    props.$hoverSpan
      ? css`
          span {
            border-bottom: 1px solid var(${borderColor});
            text-decoration: none;
          }
        `
      : css`
          border-bottom: 1px solid var(${borderColor});
        `}

  &.active {
    ${(props) =>
      props.$hoverSpan
        ? css`
            span {
              border-bottom: 1px solid var(${borderColor});
            }
          `
        : css`
            border-bottom: 1px solid var(${borderColor});
          `}
  }

  &:hover {
    color: var(${hoverColor});
    ${(props) =>
      props.$hoverSpan
        ? css`
            span {
              border-bottom-color: var(${linkColor});
            }
          `
        : css`
            border-bottom-color: var(${linkColor});
          `}
  }
`;

export const ToggleBtn = styled.button<StyledToggleButtonProps>`
  ${styledToggleButton};

  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;

  ${(props) =>
    props.$selected &&
    css`
      span {
        border-bottom: 1px solid var(${textColor});
      }
    `}

  & > svg {
    margin-right: 0.4375rem;
  }
`;

export const ToggleButtonPill = styled.button<StyledToggleButtonProps>`
  ${styledToggleButton};
  ${styleBodyM};
  border: 1px solid var(--color-black-o15);
  border-radius: 0.25rem;
  color: var(--color-black);
  display: inline-block;
  padding: 0.1875rem 0.375rem 0.125rem;
  transition: all 0.2s ease-in-out;

  span {
    color: var(--color-black);
    transition: inherit;
    display: block;
  }

  ${MQ.FROM_M} {
    padding: 0.375rem 0.875rem;
  }

  &:hover {
    background-color: var(--color-cyan-l60);
    border-color: var(--color-cyan-l60);
    color: var(--color-blue);

    span {
      color: var(--color-blue);
    }
  }

  ${(props) =>
    props.$selected &&
    css`
      background-color: var(--color-blue);
      border-color: var(--color-blue);
      color: var(--color-cyan-l60);

      span {
        color: var(--color-cyan-l60);
      }
    `}
`;

interface StyledToggleButtonProps {
  $hoverSpan?: boolean;
  $selected?: boolean;
}
