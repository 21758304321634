import { LongArrowRight28 } from "icons";
import styled from "styled-components";
import theme from "styled-theming";
import { default as NavLink } from "../../components/Link";
import {
  styleBodyM,
  styleHeadingL,
  styleLabelM,
} from "../../style/components/Typography";
import { MQ } from "../../style/mediaQueries";
import { PADDING } from "style/grid";

const backgroundColor = theme("theme", {
  undefined: "transparent",
  default: "--color-black-o05",
  white: "--color-white",
  lightgray: "--color-black-l95",
  blue: "--color-blue-d10",
  cyan: "--color-cyan-l80",
  black: "--color-black",
  backgroundImage: "transparent",
});

const textColor = theme("contentTheme", {
  black: "--color-black",
  cyan: "--color-cyan-l60",
  blue: "--color-blue",
  white: "--color-white",
});

const linkColor = theme("contentTheme", {
  black: "--color-black-o60",
  cyan: "--color-cyan-l60-o60",
  blue: "--color-blue-o60",
  white: "--color-white-o60",
});

const hoverColor = theme("contentTheme", {
  black: "--color-cyan-d05",
  cyan: "--color-coral",
  blue: "--color-blue",
  white: "--color-white",
});

const borderColor = theme("contentTheme", {
  black: "--color-black-o15",
  cyan: "--color-cyan-o40",
  blue: "--color-blue-o15",
  white: "--color-white-o15",
});

export const Heading = styled.div`
  ${styleHeadingL}
  color: var(${textColor});
  margin-bottom: 1.3125rem;
  margin-top: 0;
  transition: color 0.2s ease-in-out;

  span {
    color: var(${textColor});
    transition: color 0.2s ease-in-out;
  }
`;

export const Text = styled.div`
  ${styleBodyM};
  color: var(${textColor});
  margin-bottom: 0.4375rem;
  max-width: 780px;
`;

export const LinkText = styled.div`
  ${styleLabelM};
  color: var(${linkColor});
  margin-right: 0.875rem;
  transition: margin-right 0.2s ease-in-out, color 0.2s ease-in-out;
`;

export const IconArrow = styled(LongArrowRight28)`
  color: var(${linkColor});
  margin-left: 0.875rem;
  margin-right: 1.875rem;
  transition: margin-left 0.2s ease-in-out, color 0.2s ease-in-out;
`;

export const Link = styled(NavLink)`
  && {
    border-bottom: none;
    color: var(${textColor});
    display: grid;
    margin-top: 10px;

    &:hover {
      ${LinkText} {
        color: var(${hoverColor});
      }

      ${IconArrow} {
        margin-left: 1.75rem;
        margin-right: 0;
        color: var(${hoverColor});
      }
    }
  }
`;

export const Container = styled.div<{ $topGap: boolean }>`
  max-width: none;
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 2.625rem;
  padding-top: 2.625rem;
  width: 100%;
  position: relative;
  padding-left: ${PADDING.S};
  padding-right: ${PADDING.S};

  ${MQ.FROM_M} {
    padding-left: ${PADDING.M};
    padding-right: ${PADDING.M};
  }
  ${MQ.FROM_L} {
    ${props => props.$topGap && "padding-top: 8.125rem;"}
  }
  ${MQ.FROM_XL} {
    padding-left: ${PADDING.XL};
    padding-right: ${PADDING.XL};
  }
  ${MQ.FROM_XXXL} {
    padding-left: ${PADDING.XXXL};
    padding-right: ${PADDING.XXXL};
  }
  ${MQ.FROM_XXXXL} {
    padding-left: ${PADDING.XXXXL};
    padding-right: ${PADDING.XXXXL};
  }
`;

export const Wrapper = styled.div`
  background: var(${backgroundColor});
  background-clip: border-box;
  border-bottom: 1px solid var(${borderColor});
  border-top: 1px solid var(${borderColor});
  display: flex;
  flex-direction: column;
  margin-top: -1px;
  position: relative;
  width: 100%;
`;
