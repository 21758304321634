import styled, { css } from "styled-components";

import { ArrowRight28 } from "icons";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import Link from "components/Link";
import { styleBodyS, styleLabelM } from "style/components/Typography";
import theme from "styled-theming";
import { MQ } from "style/mediaQueries";
import { CardContainer } from "components/ProductCardItem/ProductCardItem.styled";
import { AccordionSummary } from "components/AccordionItem/AccordionItem.styled";
import { StyledAccordionItem } from "components/AccordionItem/AccordionItem.styled";

const backgroundColor = theme("theme", {
  undefined: "var(--color-white)",
  default: "var(--color-white)",
  white: "var(--color-white)",
  lightgray: "var(--color-black-l95)",
  blue: "var(--color-blue-d10)",
});

const borderColor = theme("theme", {
  undefined: "var(--color-black-l90)",
  default: "var(--color-black-l90)",
  white: "var(--color-black-l90)",
  lightgray: "var(--color-white)",
  blue: "var(--color-cyan-o20)",
});

const emphasisColor = theme("theme", {
  undefined: "var(--color-black-l95)",
  default: "var(--color-black-l95)",
  white: "var(--color-black-l95)",
  lightgray: "var(--color-black-l90)",
  blue: "var(--color-cyan-o30)",
});

const rightIconColor = theme("theme", {
  undefined: "var(--color-black)",
  default: "var(--color-black)",
  white: "var(--color-black)",
  lightgray: "var(--color-black)",
  blue: "var(--color-cyan-l60)",
});

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  position: relative;
  width: 100%;
  background-color: ${backgroundColor};
`;

export const HeadingContainer = styled(GridContainer)`
  ${columnGap}
  grid-column: col-start 1 / span 12;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  width: 100%;
  padding: 0.25rem 2.25rem 0.25rem 0.5rem;
  border-bottom: 1px solid ${borderColor};
  ${MQ.FROM_M} {
    padding: 0.25rem 4rem 0.25rem 1rem;
  }
`;

export const RightIcon = styled(ArrowRight28)`
  color: ${rightIconColor};
  position: absolute;
  top: calc(50% - 14px);
  right: calc(1rem - 12px);
  ${MQ.FROM_M} {
    right: calc(2rem - 14px);
  }
`;

export const CardLink = styled(Link)<CardLinkProps>`
  && {
    all: unset;
    display: flex;
    transition: background-color 0.1s ease-in-out;
    ${CardContainer} {
      border: 1px solid ${backgroundColor};
      border-bottom: 1px solid ${borderColor};
      padding: 0.5rem 2rem 0.5rem 0.25rem;
      ${MQ.FROM_M} {
        padding: 1rem 4rem 1rem 1rem;
      }
    }
    ${(props) =>
      !props.$loading &&
      css`
        cursor: pointer;
        :hover {
          background-color: ${emphasisColor};
          transition: background-color 0.1s ease-in-out;

          ${CardContainer} {
            border-bottom-color: ${borderColor};
          }
          ${RightIcon} {
            color: var(--color-cyan);
          }
        }
      `}
  }
`;

export const SimpleCard = styled.div`
  && {
    all: unset;
    display: flex;
    transition: background-color 0.1s ease-in-out;
    ${CardContainer} {
      border: 1px solid ${backgroundColor};
      border-bottom: 1px solid ${borderColor};
      padding: 0.5rem 2rem 0.5rem 0.25rem;
      ${MQ.FROM_M} {
        padding: 0.5rem 4rem 0.5rem 1rem;
      }
    }
  }
`;

export const CardAccordion = styled.div`
  ${StyledAccordionItem} {
    border: 1px solid ${backgroundColor};
    border-bottom: 1px solid ${borderColor};
    &:last-of-type {
      border-bottom: 1px solid ${borderColor};
    }
    transition: background-color 0.1s ease-in-out;
  }

  ${StyledAccordionItem}:hover {
    border-bottom-color: ${borderColor};
    transition: background-color 0.1s ease-in-out;
  }

  ${AccordionSummary} {
    padding: 0rem 2.25rem 0rem 0.5rem;
    & > .MuiAccordionSummary-content {
      margin: 0;
    }
    ${MQ.FROM_M} {
      padding: 0.5rem 4rem 0.5rem 1rem;
    }

    & > .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      top: calc(50% - 14px);
      right: calc(1.125rem - 12px);

      ${MQ.FROM_M} {
        right: calc(2rem - 14px);
      }
    }
  }
  ${AccordionSummary}:hover {
    background-color: ${emphasisColor};

    & > .MuiAccordionSummary-expandIconWrapper {
      color: var(--color-cyan);
    }
  }
`;

export const CardWrapper = styled.div`
  grid-column: col-start 1 / span 12;
  position: relative;
`;

export const AggregateFiguresContainer = styled.div`
  grid-column: col-start 8 / span 12;
  text-align: right;
`;

export const TittleText = styled.div<{ $fullWidth?: boolean }>`
  ${styleLabelM};
  grid-column: ${(props) =>
    props.$fullWidth ? "1/-1" : "col-start 1 / span 7"};
  align-self: center;
  padding: 0.5rem 0;
`;

export const AggregateFigures = styled.div`
  ${styleLabelM};
`;

export const AggregateDevelopment = styled.div`
  ${styleBodyS};
`;

export const AggregateSkeletonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export interface CardLinkProps {
  $loading?: boolean;
}
