import styled from "styled-components";

import { Container as GridContainer } from "style/components/Page";
import { styleBodyS, styleLabelS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import theme from "styled-theming";

const iconColor = theme("theme", {
  undefined: "--color-black",
  default: "--color-black",
  white: "--color-black",
  lightgray: "--color-black",
  blue: "--color-cyan-l60",
});

const textColor = theme("theme", {
  undefined: "--color-black-l40",
  default: "--color-black-l40",
  white: "--color-black-l40",
  lightgray: "--color-black-l40",
  blue: "--color-cyan-l60",
});

export const CardContainer = styled(GridContainer)`
  column-gap: 0.1rem;
  ${MQ.FROM_M} {
    column-gap: 1rem;
  }
  ${MQ.FROM_XL} {
    column-gap: 1.5rem;
  }
  grid-column: col-start 1 / span 12;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  width: 100%;
  padding: 0;
`;

export const CardIcon = styled.span`
  align-self: center;
  & > svg {
    color: var(${iconColor});
    min-width: 2rem;
    width: 2rem;
    height: auto;
    ${MQ.FROM_M} {
      min-width: 3.5rem;
      width: 3.5rem;
    }
  }
`;

export const CardMainBody = styled.div`
  grid-column: col-start 1 / span 8;
  display: flex;
`;

export const CardTitle = styled.div<{ $isString?: boolean }>`
  ${(props) => props.$isString && styleLabelS};
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export const CardDescription = styled.div`
  ${styleBodyS};
  color: var(${textColor});
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export const CardFiguresContainer = styled.div`
  grid-column: col-start 9 / span 10;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-self: center;

  ${MQ.FROM_M} {
    gap: 0.5rem;
  }
`;

export const CardTopRightDescription = styled.div<{ $isString?: boolean }>`
  ${(props) => props.$isString && styleLabelS};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export const CardText = styled.div<{ hasIcon?: boolean }>`
  width: ${(props) => (props.hasIcon ? "calc(100% - 2.5rem)" : "100%")};
  padding-left: 0.25rem;
  ${MQ.FROM_M} {
    padding-left: 1rem;
    width: calc(100% - 3.5rem);
  }
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-self: center;

  ${MQ.FROM_M} {
    gap: 0.5rem;
  }
`;

export const SkeletonContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  ${MQ.FROM_M} {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const CardSkeletonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
