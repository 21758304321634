import Highcharts from "highcharts/highstock";
import { LangOptions, SeriesOptionsType } from "highcharts";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import { BREAKPOINT_VALUE } from "style/breakpoints";
import {
  styleBodyS,
  styleBodyXS,
  styleHeadingS,
  styleLabelM,
  styleLabelS,
} from "style/components/Typography";
import { ThemeType } from "types/Theme";

const IS_PROD = typeof import.meta === 'undefined' || !import.meta.env || import.meta.env.PROD;

export const BASE_ICON_URL = IS_PROD
  ? "https://uilibrary.soderbergpartners.se/svg-icons/"
  : "/svg/";

const GRID_LINE_COLORS = [
  "var(--color-black-o10)",
  "var(--color-black-o15)",
  "var(--color-black-o20)",
  "var(--color-black-o40)",
  "var(--color-black-o30)",
  "var(--color-black-o20)",
  "var(--color-black-o10)",
];

export const DefaultStockChartsOptions = (
  input: Array<SeriesOptionsType>
): Highcharts.Options => ({
  chart: {
    backgroundColor: "transparent",
    panning: {
      enabled: false,
    },
    events: {
      render(this: Highcharts.Chart) {
        // @ts-ignore
        if (!this.pointCount) return;
        // @ts-ignore
        const gridLines = this.yAxis[0]?.gridGroup?.element?.children;

        if (!gridLines) return;

        for (let i = 0; i < gridLines.length; i++) {
          if (i === 1 || i === 5 || i === 3) {
            const splitPath = gridLines[i].attributes.d.nodeValue.split(" ");
            splitPath[1] =
              // @ts-ignore
              Number(splitPath[1]) + this.yAxis[0]?.maxLabelLength + 5;

            gridLines[i].setAttribute("d", splitPath.join(" "));
          }

          gridLines[i].setAttribute("stroke", GRID_LINE_COLORS[i]);
        }
      },
    },
  },
  scrollbar: {
    enabled: false,
  },
  accessibility: {
    enabled: true,
  },
  exporting: {
    enabled: false,
  },
  yAxis: {
    opposite: false,
    showLastLabel: true,
    gridLineColor: "var(-color-black-o15)",
    tickAmount: 7,
    labels: {
      align: "left",
      y: 3,
      x: 0,
      style: {
        color: "var(-color-black-o20)",
        fontSize: "12px",
      },
      formatter() {
        const tickIndex = this.axis?.tickPositions?.indexOf(this.pos);

        return tickIndex === 1 || tickIndex === 3 || tickIndex === 5
          ? this.value.toString()
          : "";
      },
    },
  },
  xAxis: {
    lineColor: "transparent",
    type: "datetime",
    minPadding: 0,
    tickLength: 2,
    startOnTick: true,
    panningEnabled: false,
    offset: 0,
    labels: {
      align: "center",
      enabled: true,
      style: {
        color: "var(--color-black-o68)",
        fontSize: "12px",
      },
      formatter(value) {
        return Highcharts.dateFormat(
          "%b %y",
          value?.value as number
        ).toUpperCase();
      },
    },
  },
  credits: {
    enabled: false,
  },
  navigator: {
    enabled: false,
  },
  rangeSelector: {
    inputEnabled: false,
    enabled: false,
  },
  legend: {
    enabled: true,
    symbolWidth: 10,
    margin: 20,
    itemStyle: {
      cursor: "default",
      color: "var(--color-black-o68)",
      fontSize: "1.125rem",
      fontWeight: "325",
      lineHeight: "1.75rem",
    },
  },
  series:
    input.length === 0
      ? [{ type: "spline", data: [] } as SeriesOptionsType]
      : (input.map((seriesItem) => ({
        ...seriesItem,
        type: "spline",
        marker: { enabled: false },
      })) as SeriesOptionsType[]),
  plotOptions: {
    spline: {
      compare: "percent",
    },
    series: {
      compare: "percent",
      events: {
        legendItemClick: function (e) {
          e.preventDefault();
        },
      },
      turboThreshold: Infinity,
      dataLabels: {
        overflow: "allow",
        crop: false,
      },
    },
  },
  tooltip: {
    outside: true,
    borderColor: "black",
    borderRadius: 10,
    borderWidth: 0,
    xDateFormat: "%d %b %Y",
    style: {
      color: "var(--color-black-l40)",
    },
    useHTML: true,
    headerFormat: `<span style='color: var(--color-black);${styleLabelS}'>{point.key}</span>`,
    pointFormat: `<div><span style="color:var(--color-black);font-size: 1rem;${styleBodyS}">{series.name}</span>: <span style="color:var(--color-black);font-size: 1rem;${styleBodyS}">&nbsp;&nbsp;{point.y}</span> <span style="font-size: 0.875rem;${styleBodyXS};">&nbsp;({point.change} %)</span></div>`,
    changeDecimals: 2,
    valueDecimals: 2,
  },
});

export const DefaultColumnChartsOptions = (
  suffix?: string,
  footerTotalText?: string,
  plotlineValue?: number,
  plotlineSeriesName?: string
): Highcharts.Options => {
  const options: Highcharts.Options = {
    chart: {
      panning: {
        enabled: false,
      },
      type: "column",
      spacingRight: 20,
      spacingLeft: 20,
    },
    title: {
      text: "",
    },
    scrollbar: {
      enabled: false,
    },
    yAxis: {
      stackLabels: {
        useHTML: true,
        enabled: plotlineValue ? true : false,
        formatter: function () {
          if (this.x === 0) {
            return "";
          }
          const percentage = (this.total / plotlineValue!) * 100;
          return `<div style="${styleLabelM}; color: var(--color-black-l40);">${percentage.toFixed(
            0
          )} %</div>`;
        },
      },
      plotLines: [
        plotlineValue
          ? {
            color: "var(--color-black-o60)",
            width: 2,
            dashStyle: "Dash",
            value: plotlineValue,
            zIndex: 4,
          }
          : {},
      ],
      reversedStacks: false,
      opposite: true,
      gridLineWidth: 1,
      title: {
        text: null,
      },
      minRange: plotlineValue ?? undefined,
      min: 0,
      labels: {
        formatter: function () {
          if (typeof this.value === "number") {
            return `${Highcharts.numberFormat(this.value, 0)} ${suffix}`;
          }

          return "";
        },
        style: {
          color: "#858081",
          fontSize: "0.75rem",
          fontWeight: "300",
          lineHeight: "1.75rem",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        },
      },
    },
    xAxis: {
      gridLineWidth: 1,
      labels: {
        style: {
          color: "#858081",
          fontSize: "0.75rem",
          fontWeight: "300",
          lineHeight: "1.75rem",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      symbolHeight: 10,
      useHTML: true,
      itemStyle: {
        cursor: "default",
        color: "#858081",
        fontSize: "1.125rem",
        fontWeight: "325",
        lineHeight: "1.75rem",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
        animation: false,
      },
    },
    tooltip: {
      backgroundColor: "white",
      borderColor: "none",
      useHTML: true,
      padding: 16,
      split: false,
      outside: true,
      shared: true,
      formatter: function () {
        let html = "";
        let total;
        let header = `<span style='display: block; margin: 0 0 1rem 0; color: var(--color-black-l40);${styleLabelS}'>${this.x}</span>`;

        let bodyStart = `<div style="
                              margin-top: 0.5rem; 
                              display: grid;
                              grid-template-columns: repeat(3, auto);
                              gap: 10px;
                        ">`;

        let pointHtml: string[] = [];

        const isPlotlineSeries = this.x === plotlineSeriesName;

        if (isPlotlineSeries) {
          const point = this.points?.[0];
          if (point) {
            total = point.total;
            const yValue = point.y;
            const yValueFormatted =
              yValue && Highcharts.numberFormat(yValue, 0);
            const p = `
              <div style="
                height: 10px;
                width: 10px;
                background-color: ${point.series.color};
                border-radius: 50%;
                align-self: center;">
              </div>
              <div style="${styleBodyS}">${point.series.name}</div>
              <div style="width: 95px; ${styleBodyS}">${yValueFormatted} ${suffix}</div>`;
            pointHtml.push(p);
          }
        } else {
          this.points?.forEach((point) => {
            total = point.total;
            const yValue = point.y;
            const yValueFormatted =
              yValue && Highcharts.numberFormat(yValue, 0);
            const p = `
            <div style="
              height: 10px;
              width: 10px;
              background-color: ${point.series.color};
              border-radius: 50%;
              align-self: center;">
            </div>
            <div style="${styleBodyS}">${point.series.name}</div>
            <div style="width: 95px; ${styleBodyS}">${yValueFormatted} ${suffix}</div>`;
            pointHtml.push(p);
          });
        }

        const body = bodyStart.concat(...pointHtml, "</div>");

        const footerValue = total && Highcharts.numberFormat(total, 0);

        let footer = `
        <div style="margin-top: 0.5rem; display: grid; grid-template-columns: repeat(10, 1fr); grid-template-areas: 'a b b b b b b b b c'; gap: 10px;">
          <div style="grid-area: a; width: 10px;"></div>
          <div style="grid-area: b; ${styleHeadingS}">${footerTotalText}</div>
          <div style="width: 95px; grid-area: c; ${styleHeadingS}">${footerValue} ${suffix}</div>
        </div>`;

        html = html?.concat(header, body, isPlotlineSeries ? "" : footer);

        return html;
      },
      valueDecimals: 0,
    },
  };

  return options;
};

export const DefaultBarChartOptions = (suffix?: string): Highcharts.Options => {
  const options: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      labels: {
        style: {
          color: "var(--color-cyan-l80)",
          fontSize: "0.8125rem",
          fontWeight: "350",
          lineHeight: "1.75rem",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "90px",
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      borderColor: "none",
      padding: 16,
      split: false,
      shared: true,
      useHTML: true,
      headerFormat: `<span style='display: block; margin: 0 0 1rem 0;font-size: 0.875rem;color: var(--color-black-l40);${styleLabelS}'>{point.key}</span>`,
      pointFormat: `<div style="margin-top: 0.5rem; 
                                display: grid; grid-template-columns: repeat(10, 1fr); 
                                grid-template-areas: 'a b b b b c'; 
                                gap: 20px;">
                    <div style="grid-area: a;
                                height: 10px;
                                width: 10px;
                                background-color: {series.color};
                                border-radius: 50%;
                                align-self: center;">
                      </div>
                    <div style="grid-area: b; ${styleBodyS}">{series.name}</div>
                    <div style="grid-area: c; ${styleBodyS}">{point.y} ${suffix}</div>
                    </div>`,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      opposite: false,
      gridLineWidth: 0.28,
      gridLineColor: "var(--color-cyan-l60-o60)",
      labels: {
        formatter: function () {
          if (typeof this.value === "number") {
            return `${Highcharts.numberFormat(this.value, 0)} ${suffix}`;
          }
          return "";
        },
        style: {
          color: "var(--color-cyan-l80)",
          fontSize: "0.8125rem",
          fontWeight: "350",
          lineHeight: "1.75rem",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        },
      },
      title: {
        text: null,
      },
    },
    title: {
      text: "",
    },
    colors: ["var(--color-cyan-l40)"],
    plotOptions: {
      bar: {
        groupPadding: 0,
        pointWidth: 16,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        animation: false,
      },
    },
  };
  return options;
};

export const GetDefaultPieChartOptions = (theme: ThemeType) => {
  let color;
  let percentageColor: string;

  switch (theme) {
    case "blue":
      color = "var(--color-cyan-l60)";
      percentageColor = "var(--color-cyan-l60)";
      break;
    case "lightgray":
      color = "var(--color-black-l40)";
      percentageColor = "var(--color-black-l40)";
      break;
    case "cyan":
    default:
      color = "var(--color-blue)";
      percentageColor = "var(--color-blue)";
      break;
  }

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "vertical",
      symbolHeight: 7,
      symbolWidth: 7,
      symbolPadding: 15,
      useHTML: true,
      itemStyle: {
        fontSize: "1.125rem",
        lineHeight: "28px",
        fontWeight: "325",
        color: color,
      },
      itemMarginTop: 8,
      itemMarginBottom: 10,
      itemHoverStyle: {
        cursor: "default",
        color: color,
      },
      margin: -10,
      labelFormatter: function () {
        const point = this as Highcharts.Point;
        return `${point.name} <span style="color: ${percentageColor};font-size: 14px; font-weight: 500;">${point.y}%</span>`;
      },
    },
    title: {
      text: "",
    },
    colors: [
      "#CCECF9",
      "#FF6380",
      "#009FDF",
      "#99D9F2",
      "#F9B9C5",
      "#D1D5E0",
      "#F9B9C5",
    ],
    plotOptions: {
      pie: {
        point: {
          events: {
            legendItemClick: function (e: any) {
              e.preventDefault();
            },
          },
        },
        showInLegend: true,
        allowPointSelect: false,
        innerSize: "60%",
        borderWidth: 0,
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
      series: {
        animation: false,
      },
    },
  };

  const options = {
    color: color,
    percentageColor: percentageColor,
    chartOptions: chartOptions,
  };

  return options;
};

export const HighChartsTheme: Highcharts.Options = {
  colors: [
    "#002359",
    "#7DC2F5",
    "#FF6380",
    "#FF99B3",
    "#333333",
    "#BFBFBF",
    "#7DC2F5",
    "#007E3A",
    "#9FD356",
    "#4B0082",
    "#A57FFF",
    "#8B0000",
    "#FF4D4D",
    "#FFA500",
    "#FFD700",
    "#FFC0CB",
    "#008080",
    "#A52A2A",
  ],
  chart: {
    style: {
      fontFamily: "Whitney, Helvetica, Arial, sans-serif",
    },
  },
};

export const LocalizationSwedish: LangOptions = {
  thousandsSep: " ",
  decimalPoint: ",",
  months: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ],
  weekdays: [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ],
  shortWeekdays: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
};

export const LocalizationEnglish: LangOptions = {
  thousandsSep: ",",
  decimalPoint: ".",
  months: [
    "January",
    "February",
    "Mars",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shortWeekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

export const DefaultCarouselOptions: CarouselProps = {
  animation: "slide",
  navButtonsAlwaysVisible: true,
  cycleNavigation: false,
  duration: 700,
  autoPlay: false,
  navButtonsProps: {
    className: "carousel-nav-button",
  },
  indicatorIconButtonProps: {
    className: "carousel-indicator-button",
  },
  activeIndicatorIconButtonProps: {
    className: "carousel-indicator-button-active",
  },
};
