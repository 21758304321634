import { IconArrow, LinkText } from "components/Banner/Banner.styled";
import Image from "components/Image";
import React, { ReactElement } from "react";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";
import { ThemeProvider } from "styled-components";
import { Container, Heading, Link, Text, Wrapper } from "./Banner.styled";
import BannerProps from "./BannerProps";

const Banner = ({
  className,
  identifier,
  heading,
  text,
  linkText,
  url,
  backgroundImage,
  target,
  theme = "blue",
  contentTheme = "cyan",
  transparentMenuGap = false,
  contentId,
  anchorName,
}: BannerProps): ReactElement => {
  const hasText = !!(heading || text);
  const hasImage = !!backgroundImage;

  const getVariant = () => {
    if (hasImage) {
      if (hasText) return "full";
      return "image";
    }
    return "text";
  };

  const variant = getVariant();

  if (backgroundImage && backgroundImage.url) {
    backgroundImage.srcSet = generateSrcSet({
      image: backgroundImage.url,
      focalPoint: backgroundImage.focalPoint,
      content: [
        {
          width: 2560,
          breakpoint: BREAKPOINT_NUMBER.XXXXL,
        },
        {
          width: 1920,
          breakpoint: BREAKPOINT_NUMBER.XXXL,
        },
        {
          width: 1680,
          breakpoint: BREAKPOINT_NUMBER.XXL,
        },
        {
          width: 1365,
          breakpoint: BREAKPOINT_NUMBER.XL,
        },
        {
          width: 992,
          breakpoint: BREAKPOINT_NUMBER.L,
        },
        {
          width: 768,
          height: Math.ceil(768 / (2 / 3)),
        },
      ],
      maxWidth: backgroundImage.uploadedWidth,
      maxHeight: backgroundImage.uploadedHeight,
      format: "jpg",
    });
  }

  return (
    <>
      <ThemeProvider
        theme={{
          theme: hasImage ? "backgroundImage" : theme,
          contentTheme: contentTheme,
          variant: variant,
        }}
      >
        <Wrapper
          key={`${identifier}Wrapper`}
          id={identifier || contentId || anchorName}
          className={className}
        >
          {backgroundImage && (
            <Image
              {...backgroundImage}
              cover={true}
              darken={hasText}
              lazyload={false}
            />
          )}
          <Container
            id={`${identifier}Container`}
            key={`${identifier}Container`}
            className={className}
            $topGap={transparentMenuGap}
          >
            {heading && <Heading>{heading}</Heading>}
            {text && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {linkText && (
              <Link to={url} target={target} underline="none">
                <LinkText>
                  {linkText}
                  <IconArrow aria-hidden="true" />
                </LinkText>
              </Link>
            )}
          </Container>
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

export default React.memo(Banner);
